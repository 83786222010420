<template>
    <div class="visitor">
        <template class="list" v-if="isLoad">
            <template v-if="list.length > 0">
                <van-list
                    v-model="loading"
                    :finished="finished"
                    finished-text="到底啦〜想不到你看到了这里^_^"
                    @load="getGoodsVisitorUser"
                >
                    <div class="visitor-grid" v-for="(item, index) in list" :key="index">
                        <img :src="item.user_info.headimgurl" />
                        <span class="name">{{item.user_info.nickname}}</span>
                        <span class="date">{{item.create_time}}</span>
                    </div>
                </van-list>
            </template>
            <no-data v-else content="这里没有数据"></no-data>
        </template>
    </div>
</template>

<script>
import { getGoodsVisitorUser } from "@/http/api";
import login from "@/common/islogin";
export default {
    name: "",
    components: {},
    props: {},
    data() {
        return {
            list: [],
            isLoad: false,
            finished: true,
            loading: false,
            pages: 1,
            share_id: this.$route.query.share_id,
            goods_id: this.$route.query.goods_id
        };
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {
        if ((this.userInfo = this.$login.isLogin(window.location.href))) {
            // this.userInfo = this.$LStorage.getItem("user_info");
            this.getGoodsVisitorUser();
        }
    },
    methods: {
        // 我的分享的商品访客列表
        async getGoodsVisitorUser() {
            const res = await getGoodsVisitorUser({
                data: {
                    user_id: this.userInfo.user_id,
                    share_id: this.share_id,
                    goods_id: this.goods_id,
                    pages: this.pages
                }
            });
            this.isLoad = true;
            this.$loading = false;
            if (res.length > 0) {
                this.list = this.list.concat(res.data);
                this.pages++;
                this.finished = true;
            } else {
                this.finished = false;
            }
        }
    }
};
</script>

<style scoped lang="scss">
.visitor {
    background: #f6f6f6;
    padding-top: 0.2rem;
    min-height: 100vh;
    .visitor-grid {
        height: 1.4rem;
        display: flex;
        align-items: center;
        background: #fff;
        margin-bottom: 0.2rem;
        padding: 0 3vw;
        position: relative;
        img {
            width: 14vw;
            height: 14vw;
            border-radius: 50%;
            margin-right: 0.2rem;
        }
        .name {
            font-size: 0.28rem;
            color: #000;
        }
        .date {
            font-size: 0.24rem;
            color: #878787;
            position: absolute;
            right: 3vw;
        }
    }
}
</style>
